//
// user-variables.scss
// Use this to override Bootstrap and Silicon variables
//

// Example of a variable override to change Silicon background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

// Colors
$primary: #5e5482;
$secondary: #7c93f6;
$info: #4c82f7;
$success: #22c55e;
$warning: #ffba08;
$danger: #ef4444;
$light: #eff4ff;
$dark: #0b0f19;

// Faded colors map
$theme-faded-colors: (
  "secondary": #e5ecfe,
  "success": #e4fcd6,
  "info": #d5f5ff,
  "warning": #fff6d1,
  "light": #f7faff,
) !default;

// Body
$body-color: #9e98b4;

// Links
$link-color: $body-color;
$link-decoration: none;
$link-hover-color: darken($link-color, 30%);
$link-hover-decoration: underline;

// Theme shadows
$box-shadow: 0 4px 50px rgba(62, 79, 177, .04);

// Form switch
$form-switch-checked-bg: $secondary;

// Accordion
$accordion-button-active-color: $secondary;
$accordion-icon-box-active-bg: $secondary;
$accordion-icon-box-active-box-shadow: none;

// Other potential overrides (if they were changed from the template defaults)
// Add these only if the values differ from the original template"s `_variables.scss`

/*
$gray-100:                    #f3f6ff;
$gray-200:                    #eff2fc;
$gray-300:                    #e2e5f1;
$gray-400:                    #d4d7e5;
$gray-500:                    #b4b7c9;
$gray-600:                    #9397ad;
$gray-700:                    #565973;
$gray-800:                    #33354d;
$gray-900:                    #0b0f19;
$black:                       #000;
$white:                       #fff;

// Hero

.hero-element1 {
  bottom: 2rem;
  left: -1rem;
  @media (max-width: 992px) {
    bottom: -2rem;
    left: 1rem;
  }
  @media (max-width: 767px) {
    max-width: 20rem;
  }
}

.hero-element2 {
  top: 13rem;
  right: -4vw;
  @media (max-width: 767px) {
    top: 10rem;
    max-width: 12rem;
  }
}

// Reusable Classes

.bg-light-hover {
  &:hover {
    background-color: #e5ecfe;
  }
}

.w-5rem {
  width: 5rem;
}

.btn-icon-sm {
  width: 2rem;
  height: 2rem;
}

.btn-icon-md {
  width: 3.125rem;
  height: 3.125rem;
}

.fw-600 {
  font-weight: 600;
}

.bg-gradient-secondary {
  background: linear-gradient(24.82deg, #7c93f6 -4.57%, #e5ecfe 127.53%);
}

.lh-1-3 {
  line-height: 1.3;
}

.min-w-21rem {
  min-width: 21rem;
}

.min-w-18rem {
  min-width: 18rem;
}

.card-with-arrow {
  &::after {
    position: absolute;
    bottom: -2.5rem;
    left: -.5rem;
    width: 0;
    height: 0;
    content: "";
    border-top: 48px solid #f7faff;
    border-right: 20px solid rgba(0, 0, 0, 0);
    border-left: 20px solid rgba(0, 0, 0, 0);
    transform: rotate(19deg);
  }
}

/* Media Queries */

@media (min-width: 1200px) {
  .mt-xl-n4rem {
    margin-top: -4rem;
  }
}

@media (max-width: 992px) {
  html,
  body {
    overflow-x: hidden;
  }
  html body .fs-base-tablet {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  /* stylelint-disable selector-class-pattern */
  .navbarNav {
    width: 100%;
  }
  /* stylelint-enable selector-class-pattern */

  html body .fs-sm-tablet {
    font-size: 14px;
  }

  .step {
    display: block;
    text-align: center;
    &::before,
    &::after {
      display: none;
    }
    .step-number {
      margin: 0 auto;
    }
    .step-body {
      padding-left: 0;
    }
  }
}
